<template>
  <span class="ml-2">
    <i class="fas fa-check text-success icon-sm" v-if="required && provided"></i>
    <i class="fas fa-asterisk text-danger icon-sm" v-if="required && !provided"></i>
  </span>
</template>
<script>
export default {
  props: {
    provided: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      required: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
