<template>
  <div class="row">
    <div class="col-md-6">
      <b-table-simple class="table table-sm table-borderless table-vertical-center mb-0">
        <b-thead>
          <b-tr>
            <b-th class="p-0 w-50px"></b-th>
            <b-th class="p-0 min-w-200px"></b-th>
            <b-th class="p-0 min-w-100px"></b-th>
            <b-th class="p-0 min-w-40px"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td class="text-muted">{{ $t('COMMON.AUSGESTELLT') }}:</b-td>
            <b-td>
              {{ parseDate(teilnehmer.passAusstellungsdatum) }}
              <PassCheckIcon
                v-if="konfiguration"
                :provided="teilnehmer.passAusstellungsdatum != null"
                :required="konfiguration.passAusstellungsdatumRequired"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('COMMON.GUELTIGBIS') }}:</b-td>
            <b-td>
              {{ parseDate(teilnehmer.passGueltigkeitsdatum) }}
              <PassCheckIcon
                v-if="konfiguration"
                :provided="teilnehmer.passGueltigkeitsdatum != null"
                :required="konfiguration.passGueltigkeitsdatumRequired"
              ></PassCheckIcon>
              <span
                :title="$t('KUNDEN.PASS7MONTATEGIELTIG')"
                v-b-tooltip.hover
                class="bg-warning rounded p-1"
                v-if="isPassLessThanSevenMonthsValid"
              >
                <i class="flaticon2-exclamation icon-1x text-white"></i>
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('VORGAENGE.AUSSTELLUNGSORT') }}:</b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'passOrt') }}
              <PassCheckIcon
                v-if="konfiguration"
                :provided="teilnehmer.passOrt != null"
                :required="konfiguration.passOrtRequired"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('VORGAENGE.AUSSTELLUNGSLAND') }}:</b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'passLand') }}
              <PassCheckIcon
                v-if="konfiguration"
                :provided="teilnehmer.passLand != null"
                :required="konfiguration.passLandRequired"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('KONFIGURATION.geburtsortRequired') }}:</b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'geburtsort') }}
              <PassCheckIcon
                v-if="konfiguration"
                :provided="teilnehmer.geburtsort != null"
                :required="konfiguration.geburtsortRequired"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <div class="col-md-6">
      <b-table-simple class="table table-sm table-borderless table-vertical-center">
        <b-thead>
          <b-tr>
            <b-th class="p-0 w-50px"></b-th>
            <b-th class="p-0 min-w-200px"></b-th>
            <b-th class="p-0 min-w-100px"></b-th>
            <b-th class="p-0 min-w-40px"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td class="text-muted">{{ $t('COMMON.NUMMER') }}:</b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'passNummer') }}
              <PassCheckIcon
                v-if="konfiguration"
                :provided="teilnehmer.passNummer != null"
                :required="konfiguration.passNummerRequired"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('REISEN.COMMON.NAME') }}:</b-td>
            <b-td>
              {{ teilnehmer.passName || '-' }}
              <PassCheckIcon :provided="teilnehmer.passName != null" :required="false"></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('KONFIGURATION.passartRequired') }}:</b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'passArt') }}
              <PassCheckIcon
                v-if="konfiguration"
                :provided="teilnehmer.passArt != null"
                :required="konfiguration.passartRequired"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="text-muted">{{ $t('KONFIGURATION.geschlechtRequired') }}:</b-td>
            <b-td>
              {{ getTeilnehmerDataByKey(teilnehmer, 'geschlecht') }}
              <PassCheckIcon
                v-if="konfiguration"
                :provided="teilnehmer.geschlecht != null"
                :required="konfiguration.geschlechtRequired"
              ></PassCheckIcon>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import PassCheckIcon from '@/components/vorgaenge/passdaten-check-icon.vue';
const moment = require('moment');

export default {
  components: { PassCheckIcon },

  props: {
    reiseterminStartdatum: { type: String },
    teilnehmer: { type: Object },
    konfiguration: { type: Object },
  },
  computed: {
    isPassLessThanSevenMonthsValid() {
      if (this.teilnehmer.passGueltigkeitsdatum && this.reiseterminStartdatum) {
        return (
          moment(this.teilnehmer.passGueltigkeitsdatum).diff(moment(this.reiseterminStartdatum), 'months') < 7
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    parseDate(date) {
      return date ? this.$options.filters.date(date) : '-';
    },
    getTeilnehmerDataByKey(teilnehmer, key) {
      return teilnehmer[key] || '-';
    },
  },
};
</script>

<style lang="scss" scoped>
table td {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
