<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header">
      <div class="card-title">
        <ActionButton class="mr-3" v-b-toggle.aufgaben-collapse title="Ausklappen"
          :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'" @click="toggleCollapse" />
        <h3 class="card-label">Aufgaben</h3>
        <b-form-checkbox v-model="alleAufgabenAnzeigen" switch style="display: flex; font-size: 14px">
          Mit erledigten Aufgaben
        </b-form-checkbox>
      </div>
      <div class="card-toolbar">
        <b-button v-for="(department, index) in departmentsOfAufgabenBearbeiter" class="px-1 mx-1"
          :class="`border-${departmentButtonClass(department).border}`" :style="{
            'background-color':
              selectedDepartment === departmentButtonClass(department).label
                ? departmentButtonClass(department).color
                : '',
            'border-color': departmentButtonClass(department).color,
            color:
              selectedDepartment === departmentButtonClass(department).label
                ? departmentButtonClass(department).fontColor
                : '',
          }" :key="index" style="
            height: calc(1.5em + 1.3rem + 2px);
            width: calc(1.5em + 1.3rem + 2px);
            font-weight: bold;
            font-size: 0.8rem;
          " @click="changeDepartment(departmentButtonClass(department).label)">
          {{ departmentButtonClass(department).label }}
        </b-button>
      </div>
    </div>

    <b-collapse visible id="aufgaben-collapse">
      <div class="card-body" v-if="reiseterminObj">
        <div v-if="reiseterminObj?.aufgaben.length > 0">
          <AufgabenWidget @clickAufgabenWidget="onClickAufgabenWidget" :aufgaben="selectedDepartmentAufgaben"
            ansicht="reisetermine-view" :reiseterminObj="reiseterminObj" :onPlusSignClicked="plusSignClicked">
          </AufgabenWidget>
        </div>
        <div v-else class="font-weight-bold text-muted">Keine Aufgaben vorhanden</div>
        <AufgabeDetailsModal @aufgabeBearbeitet="onAufgabeBearbeitet" :showAttachments="false"></AufgabeDetailsModal>
        <AufgabeErstellenModal :prefixedVerlinkungen="aufgabenVerlinkung"></AufgabeErstellenModal>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import ActionButton from '@/components/common/action-button.vue';
import AufgabeDetailsModal from '@/components/aufgaben/aufgabe-details-modal.vue';
import AufgabeErstellenModal from '@/components/aufgaben/aufgabe-erstellen-modal.vue';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';
import { SET_AUFGABE_DETAIL_DATA, REFETCH_SINGLE_AUFGABE } from '@/core/aufgaben/stores/aufgaben.module';
import { mapGetters, mapState } from 'vuex';
import { departmentButtonClass } from '@/core/common/helpers/utils';

export default {
  props: {
    reiseterminObj: { type: Object },
  },
  components: {
    ActionButton,
    AufgabeDetailsModal,
    AufgabenWidget,
    AufgabeErstellenModal,
  },
  data() {
    return {
      isCollapseOpen: true,
      alleAufgabenAnzeigen: true,
      selectedDepartment: null,
      departmentsArray: [],
      aufgabenVerlinkung: [],
    };
  },
  mounted() {
    if (this.$route.fullPath.includes('releasepruefung')) {
      if (this.departmentsOfAufgabenBearbeiter.includes('Destination Operations Management')) {
        this.selectedDepartment = 'DOM';
      } else {
        this.selectedDepartment = null;
      }
    }
  },

  computed: {
    ...mapGetters([
      'getPmTeamUsersIds',
      'getDomTeamUsersIds',
      'getFlugTeamUsersIds',
      'getCsmTeamUsersIds',
      'getTechTeamUsersIds',
      'getYieldControllingTeamUsersIds',
      'getYieldFulfillmentTeamUsersIds',
    ]),
    ...mapState({
      users: state => state.users['users'],
    }),
    departmentsOfAufgabenBearbeiter() {
      // überprüfung, ob das comments array leer ist
      if (!this.reiseterminObj) {
        return [];
      } else {
        // es werden sich alle bearbeiter aus den aufgaben geholt -> geht über das gesamte array von Objects
        // ergebnis ist ein neues array mit den IDs
        const bearbeiterIds = this.erledigteAufgabenFilter
          .map(aufgabe => aufgabe.bearbeiter)
          .filter(bearbeiter => bearbeiter != null);

        // es werden sich alle Teams aus den aufgaben geholt -> geht über das gesamte array von Objects
        // ergebnis ist ein neues array mit den Teams
        const teamList = this.erledigteAufgabenFilter
          .map(aufgabe => aufgabe.team)
          .filter(team => team != null);

        // geht über das users Array und überprüft, ob die ID in dem bearbeiter array enthalten ist
        const usersList = this.users.filter(user => bearbeiterIds.includes(user.id));

        // geht über jedes usersList array und nimmt sich da dann das department heraus
        const departments = usersList.map(user => user.department);

        // es wird dann ein Set daraus gemacht, damit nur unique departments gespeichert werden
        const teamUndDepartmentListe = [...teamList, ...departments];
        return [...new Set(teamUndDepartmentListe)];
      }
    },
    selectedDepartmentAufgaben() {
      // Filter nach ausgewähltem Department
      if (this.selectedDepartment) {
        return this.erledigteAufgabenFilter.filter(aufgabe => {
          // überprüft, ob die aufgabe einen bearbeiter hat und ob der Bearbeiter zu der ausgewählten abteilung passt
          const isBearbeiterInDepartment =
            aufgabe.bearbeiter != null && this.bearbeiterInSelectedDepartment(aufgabe.bearbeiter);

          // überprüft, ob die aufgabe ein Tam hat und ob das Team zu der ausgewählten abteilung passt
          const isTeamInDepartment = aufgabe.team != null && this.teamInSelectedDepartment(aufgabe.team);
          return isBearbeiterInDepartment || isTeamInDepartment;
        });
      }
      // return der gefilterten Aufgaben
      return this.erledigteAufgabenFilter;
    },
    erledigteAufgabenFilter() {
      return (
        this.reiseterminObj?.aufgaben.filter(
          aufgabe => this.alleAufgabenAnzeigen || aufgabe.status !== 'Erledigt'
        ) || []
      );
    },
  },
  watch: {
    alleAufgabenAnzeigen() {
      this.selectedDepartment = null;
    },
    reiseterminObj() {
      if (this.$route.fullPath.includes('releasepruefung')) {
        if (this.departmentsOfAufgabenBearbeiter.includes('Destination Operations Management')) {
          this.selectedDepartment = 'DOM';
        } else {
          this.selectedDepartment = null;
        }
      }
    },
  },
  methods: {
    plusSignClicked() {
      this.$bvModal.show('modalAufgabeEdit');
      this.aufgabenVerlinkung = [
        {
          id: 0,
          typ: 'Reisetermin',
          artifactId: this.reiseterminObj.id,
          artifactText: this.reiseterminObj.reiseterminkuerzel,
        },
      ];
    },
    departmentButtonClass,
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
    onAufgabeBearbeitet(response) {
      this.$emit('aufgabeBearbeitet', response);
    },
    onClickAufgabenWidget(aufgabe) {
      this.$store
        .dispatch(REFETCH_SINGLE_AUFGABE, {
          aufgabenId: aufgabe.id,
        })
        .then(aufgabe => {
          this.$store.commit(SET_AUFGABE_DETAIL_DATA, aufgabe);
        });
      this.$root.$emit('bv::show::modal', 'modalAufgabeDetails');
    },
    changeDepartment(departmentLabel) {
      this.selectedDepartment = this.selectedDepartment === departmentLabel ? null : departmentLabel;
    },
    // es wird ein bearbeiter übergeben und wird geprüft, ob der bearbeiter der Abteilung zugehörig ist
    bearbeiterInSelectedDepartment(bearbeiter) {
      if (this.selectedDepartment === 'PM') {
        return this.getPmTeamUsersIds.includes(bearbeiter);
      } else if (this.selectedDepartment === 'YF') {
        return this.getYieldFulfillmentTeamUsersIds.includes(bearbeiter);
      } else if (this.selectedDepartment === 'DOM') {
        return this.getDomTeamUsersIds.includes(bearbeiter);
      } else if (this.selectedDepartment === 'Flug') {
        return this.getFlugTeamUsersIds.includes(bearbeiter);
      } else if (this.selectedDepartment === 'CSM') {
        return this.getCsmTeamUsersIds.includes(bearbeiter);
      } else if (this.selectedDepartment === 'Tech') {
        return this.getTechTeamUsersIds.includes(bearbeiter);
      } else if (this.selectedDepartment === 'YC') {
        return this.getYieldControllingTeamUsersIds.includes(bearbeiter);
      } else {
        return false;
      }
    },
    // wird überprüft, ob das Team mit einem team aus der departmentButtonClass und dem ausgewählten team übereinstimmt
    teamInSelectedDepartment(team) {
      const teamClass = departmentButtonClass(team);
      return teamClass && teamClass.label === this.selectedDepartment;
    },
  },
};
</script>
<style></style>
